<template>
  <div>
    <h1 class="text-4xl text-center py-10">Branches</h1>

    <div>
      <div class="relative w-full max-w-6xl mx-auto my-3">

        <div class="my-3 relative" style="min-height: 400px">

          <overly class="rounded" background="bg-blue-100" v-if="loading">
            <spinner class="text-red-600 h-10 w-10"></spinner>
          </overly>

          <div class="grid md:grid-cols-3 gap-4 py-4 w-full">
            <div v-for="item in  items"
                 :class="$store.state.appData.branchId == item.id ? 'bg-red-300':''"
                 class="border border-gray-200 rounded shadow p-4"
                 style="min-height: 200px">
              <div>{{ item.name }}</div>
              <btn @click="switchBranch(item)"   variant="info-outline">Go</btn>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Overly from "../../../components/overly";
import Spinner from "../../../components/spinner";
import BatchItem from "../home/components/batch-item";
import Btn from "../../../components/btn";
import {get_token_hash, isLoggedIn} from "../../../store/axios";
export default {
  name: "Branch",
  components: { Btn, BatchItem, Spinner, Overly },
  data() {
    return {
      items:[],
      loading: true
    }
  },
  computed:{
    linkParams( ){
      const tHash = get_token_hash();
      return tHash && isLoggedIn() ? '?__t=' + tHash:'';
    }
  },
  methods:{
    switchBranch( item ){

      this.$store.dispatch('createBranchSwitchToken', {
        success:  ( data ) => {

          if( data.success ) {
            window.location = 'http://' + item.domain + '?__t=' + data.token + "&lt=" + get_token_hash();
          } else {
            window.location = 'http://' + item.domain;
          }

        }
      });
    },
    loadItems() {
      this.$store
          .dispatch('public/branches', {
            success: (response) => {
              this.items = response.data.branches;
            },
            error: () => {},
          })
          .finally(() => {
            this.loading = false
          });
    },
  },
  created() {
    this.loadItems();
  }

};
</script>

<style scoped>
</style>
